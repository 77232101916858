<template>
  <el-submenu :index="parentItem.id">
    <template slot="title">
      <i :class="parentItem.icon" />
      <span>{{ parentItem.name }}</span>
    </template>
    <template v-for="item in parentItem.children">
      <sider-menu-item
        v-if="item.children && item.children.length"
        :parent-item="item"
      ></sider-menu-item>
      <el-menu-item v-else :index="item.path" :key="item.id" :route={path:item.path,query:{id:item.id}}>
        <span>{{ item.name }}</span>
      </el-menu-item>
    </template>
  </el-submenu>
</template>

<script>
export default {
  name: 'SiderMenuItem',
  props: {
    parentItem: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="less" scoped></style>
