<!--
 * @Author: your name
 * @Date: 2021-08-16 15:09:42
 * @LastEditTime: 2021-09-08 17:15:09
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \全过程\prcoress\src\views\layout\BasicLayout.vue
-->
<template>
  <el-container class="basic-layout">
    <el-header style="height: 64px">
      <Header @changeTab="changeTab" :isDisabled="false" />
    </el-header>
    <el-container>
      <el-aside width="220px">
        <div class="title" v-if="this.menuType === 1">
          <div class="titleName">
            <div class="name">{{ projectName }}</div>
          </div>
          <div class="pro-change" v-loading.fullscreen.lock="fullscreenLoading" @click="changeProject">
            <i class="iconfont icon-other-qiehuan"></i><span>项目切换</span>
          </div>
        </div>
        <div class="menu">
          <AsideMenu :menuType="menuType" :key="tabKey" />
        </div>
      </el-aside>
      <el-main>
        <el-card>
          <div style="padding:16px;">
            <transition name="fade-transform" mode="out-in">
              <router-view />
            </transition>
          </div>
        </el-card>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Header from './Header';
import AsideMenu from './AsideMenu';
export default {
  components: {
    Header,
    AsideMenu
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    this.timer = null;
  },
  created() {
    this.projectName = sessionStorage.getItem('projectName');
  },
  data() {
    return {
      fullscreenLoading: false,
      timer: null,
      menuType: 1,
      projectName: '',
      tabKey: 'tabKey'
    };
  },
  methods: {
    changeTab(data) {
      this.menuType = data;
    },
    // 项目切换
    changeProject() {
      sessionStorage.removeItem('currentMenuId');
      sessionStorage.removeItem('currentPath');
      sessionStorage.removeItem('currentTab');
      sessionStorage.removeItem('projectId');
      sessionStorage.removeItem('projectName');
      sessionStorage.removeItem('isProject');
      this.fullscreenLoading = true;
      this.timer = setTimeout(() => {
        this.$router.push('/projectMana');
        this.fullscreenLoading = false;
      }, 1500);
    }
  }
};
</script>

<style lang="less" scoped>
.basic-layout {
  width: 100%;
  height: 100%;
}

.el-header {
  padding: 0;
}

.el-container {
  .el-aside {
    background-color: #272b38;
  }
  .menu {
    height: calc(100vh - 198px);
    overflow-y: auto;
  }
  // 隐藏滚动条
  .menu::-webkit-scrollbar {
    display: none;
  }
  .el-main {
    height: calc(100vh - 64px);
    width: 100%;
    background-color: #f3f6fe;
    padding: 16px;
    .el-card {
      border: none;
      min-height: 100%;
      word-wrap: break-word;
      word-break: break-all;

      /deep/ .el-card__body {
        padding: 0;
      }
    }
  }
}
.title {
  // width: 100%;
  height: 130px;
  position: relative;
  .titleName {
    height: 102px;
    width: 100%;
    background: #117bf7;
    border-radius: 0 0 40% 40%;
  }
  .name {
    color: #fff;
    padding-top: 25px;
    font-size: 18px;
    left: 50%;
    transform: translate(-50%, 0);
    font-weight: bold;
    position: absolute;
    z-index: 2;
    text-align: center;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .pro-change {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 31px;
    background: #fff;
    color: #117bf7;
    border-radius: 20px;
    position: absolute;
    bottom: 16px;
    left: 60px;
    z-index: 2;
    cursor: pointer;
    .iconfont {
      font-size: 18px;
    }
  }
}
</style>
